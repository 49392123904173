.demo {
  margin-top: 15px;
  
  .art-piece {
    height: calc(100vh - 113px);

     img {
      display: block;
      margin: 15px auto 0 auto;
      height: calc(100vh - 113px);
    }
  }

  .footer {
    margin: 15px 0;
    display: flex;
    height: 68px;
    z-index: 999;
    position: relative;
    justify-content: space-between;
    align-items: center;

    img {
      max-width: 256px;
    }

    .display-text {
      font-size: 34px;
      font-weight: bold;
      display: flex;

      div {
        width: 100px;
      }

      .timer {
        color: red;
        padding-left: 15px;
      }
    }
  }
}