.description.featured-piece-header {
    margin-bottom: 0px;

    .container {
        padding-bottom: 0px;
    }
}

.description.featured-piece-image {
    margin-bottom: 2em;
    
    .container {
        padding-top: 0px;
    }
}
