#gallery-display {
  margin-top: 15px;

  #gallery-display-content {
    display: flex;

    #gallery-display-piece {
      height: calc(100vh - 113px);

      &.display-piece-full {
        width: 100%
      }

      &.display-piece-small {
        width: 50%;
        padding-left: 20px;
      }

       img {
        display: block;
        margin: 15px auto 0 auto;
        height: calc(100vh - 113px);
      }
    }

    #gallery-display-description {
        padding: 20px 50px;
    }
  }

  .footer {
    margin: 15px 0;
    display: flex;
    height: 68px;
    z-index: 999;
    position: relative;
    justify-content: space-between;
    align-items: center;

    img {
      max-width: 256px;
    }

    .display-text {
      font-size: 34px;
      font-weight: bold;
      display: flex;

      div {
        width: 100px;
      }

      .timer {
        color: red;
        padding-left: 15px;
      }
    }
  }
}
